body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

.blink_me {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% { opacity: 10%; }
}

.loader {
    margin-top: 10px;
    width: 120px;
    height: 20px;
    -webkit-mask: linear-gradient(90deg,rgba(30, 136, 299, 0.5) 70%,#0000 0) left/20% 100%;
    background: linear-gradient(rgba(30, 136, 299, 0.5) 0 0) left -25% top 0 /20% 100% no-repeat #ddd;
    animation: l7 3s infinite steps(6);
}

@keyframes l7 {
    100% {background-position: right -25% top 0}
}

.App {
    height: 100vh;
    width: 100vw;
}

.MuiAccordionDetails-root .MuiTypography-root {
    width: 100%;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgba(30, 136, 299, 0.3) !important;
}

.MuiTabs-indicator,
.MuiTab-root {
    min-width: 120px !important;
}

/* background color */
/* .setColorOrbitValid circle:nth-child(1) {
    stroke: green;
} */

/* foreground color */
.setColorOrbitValid circle:nth-child(2) {
    stroke: green;
}


/* background color */
/* .setColorOrbitInValid circle:nth-child(1) {
    stroke: red;
} */

/* foreground color */
.setColorOrbitInValid circle:nth-child(2) {
    stroke: red;
}

/* path color */
.setColorLine path:nth-child(1) {
    stroke: rgb(100, 0, 100) !important;
}

/* petal path color */
.setColorLinePetal path:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.6) !important;
}

/* text color */
.setColorLine text textPath:nth-child(1) {
    fill: rgb(100, 0, 100) !important;
}

/* set color isPath */
.setColorPath path:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.6);
}
.setColorPath text textPath:nth-child(1) {
    fill: rgb(100, 0, 100, 0.8) !important;
}

/* set color isPathCreate */
.setColorPathCreate path:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.6);
}
.setColorPathCreate text textPath:nth-child(1) {
    fill: rgb(100, 0, 100, 0.8) !important;
}

/* set color isPathRead */
.setColorPathRead path:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.6);
}
.setColorPathRead text textPath:nth-child(1) {
    fill: rgb(100, 0, 100, 0.8) !important;
}

/* set color isPathUpdate */
.setColorPathUpdate path:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.6);
}
.setColorPathUpdate text textPath:nth-child(1) {
    fill: rgb(100, 0, 100, 0.8) !important;
}

/* set color isPathDelete */
.setColorPathDelete path:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.6);
}
.setColorPathDelete text textPath:nth-child(1) {
    fill: rgb(100, 0, 100, 0.8) !important;
}

/* set color isResource */
.setColorIsResource circle {
    fill: #eee0ed !important;
}
.setColorIsResource circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsResource circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isAction */
.setColorIsAction circle {
    fill: #eee0ed !important;
}
.setColorIsAction circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsAction circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isSubject */
.setColorIsSubject circle {
    fill: #eee0ed !important;
}
.setColorIsSubject circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsSubject circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isPath */
.setColorIsPath circle {
    fill: #eee0ed !important;
}
.setColorIsPath circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsPath circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isPathCreate */
.setColorIsPathCreate circle {
    fill: #eee0ed !important;
}
.setColorIsPathCreate circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsPathCreate circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isPathRead */
.setColorIsPathRead circle {
    fill: #eee0ed !important;
}
.setColorIsPathRead circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsPathRead circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isPathUpdate */
.setColorIsPathUpdate circle {
    fill: #eee0ed !important;
}
.setColorIsPathUpdate circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsPathUpdate circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isPathDelete */
.setColorIsPathDelete circle {
    fill: #eee0ed !important;
}
.setColorIsPathDelete circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsPathDelete circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isVC */
.setColorIsVC circle {
    fill: #eee0ed !important;
}
.setColorIsVC circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsVC circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isHolder */
.setColorIsHolder circle {
    fill: #eee0ed !important;
}
.setColorIsHolder circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsHolder circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isAndOperator */
.setColorIsAndOperator circle {
    fill: #eee0ed !important;
}
.setColorIsAndOperator circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsAndOperator circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isOrOperator */
.setColorIsOrOperator circle {
    fill: #eee0ed !important;
}
.setColorIsOrOperator circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsOrOperator circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isIssuer */
.setColorIsIssuer circle {
    fill: #eee0ed !important;
}
.setColorIsIssuer circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsIssuer circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* set color isClaim */
.setColorIsClaim circle {
    fill: #eee0ed !important;
}
.setColorIsClaim circle:nth-child(1) {
    stroke: rgb(100, 0, 100, 0.1);
}
.setColorIsClaim circle:nth-child(2) {
    stroke: rgb(100, 0, 100, 0.1);
}

/* text color */
.setColorOrbit {
    fill: purple !important;
}

/* background color */
.setColorOrbit circle:nth-child(1) {
    stroke: purple;
}

/* foreground color */
.setColorOrbit circle:nth-child(2) {
    stroke: purple;
}

/* circle center color */
.setColorOrbit circle:nth-child(3) {
    fill: rgba(128, 0, 128, 0.2) !important;
}

.swal2-container {
    z-index: 1500 !important;
}

.addMarkerStart {
    marker-start: url("#arrowheadstart");
}

.addMarkerEnd {
    marker-end: url("#arrowheadend");
}

.setColorLine .addMarkerStart {
    marker-start: url("#arrowheadstart-hover");
}

.setColorLine .addMarkerEnd {
    marker-end: url("#arrowheadend-hover");
}

.setColorPath .addMarkerStart {
    marker-start: url("#arrowheadstart-path");
}

.setColorPath .addMarkerEnd {
    marker-end: url("#arrowheadend-path");
}

.setColorLine .implementEnumLink.addMarkerStart {
    marker-start: url("#arrowheadstart-hover");
}

.setColorLine .implementEnumLink.addMarkerEnd {
    marker-end: url("#arrowheadend-hover");
}

.implementEnumLink.addMarkerStart {
    marker-start: url("#arrowheadstart-enum");
}

.implementEnumLink.addMarkerEnd {
    marker-end: url("#arrowheadend-enum");
}

.Toastify__toast-body {
    white-space: pre-line;
}

.searchByObjectName {
    white-space: nowrap;
    flex-wrap: nowrap;
}

.searchByObjectName > div {
    height: 40px;
}

@media (max-width: 1000px) {
    .searchByObjectName {
        display: none !important;
    }
}

.leftIconMouseOver {
    cursor: pointer;
}

.leftIconMouseOver:hover {
    background-color: #E5E5E5
}

.draggableItems:hover > svg {
    fill: #1e88e5 !important;
}

.draggableItems:hover > p {
    color: #1e88e5;
}

.draggableItems:hover {
    cursor: pointer;
}

.draggableItems:hover .cls-12 {
    stroke: #1e88e5 !important;
}

.draggableItems:hover > svg #enumID {
    stroke: #1e88e5 !important;
}