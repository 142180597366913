:focus-visible {
    outline: none !important;
    border: 0;
}

.MuiDialog-paperWidthSm {
    overflow-x: hidden;
}

.modalDS .MuiDialog-paperWidthSm {
    width: 850px !important;
    max-width: 75% !important;
}

.modalCodeEditor .MuiDialog-paperWidthSm {
    width: 75% !important;
    max-width: 75% !important;
}

.textFieldInsideModal {
    margin: 0 !important;
    padding: 10px !important;
    width: calc(100% - 25px) !important;
    height: 100% !important;
}

.textFieldInsideModal fieldset {
    border: 0 !important;
}

.modalCodeEditor .ace-monokai .ace_print-margin {
    width: 0 !important;
}
